<template>
  <div class="news-list" v-loading="pageLoading">
    <div class="cont">
    <div class="search-box">
        <el-input placeholder="请输入关键字" v-model="search_key" class="input-with-select">
            <el-select v-model="selectIdx" slot="prepend" placeholder="请选择">
                <el-option label="统一社会信用代码" value="1"></el-option>
                <el-option label="企业信息" value="2"></el-option>
                <el-option label="企业法人" value="3"></el-option>
            </el-select>
            <el-button slot="append" @click="doSearch()">搜索</el-button>
        </el-input>
    </div>
      <div class="cont-list" v-if="dataList.length>0">
        <div
          class="border-b list-cell cursor-pointer p-20"
          v-for="(item, index) in dataList" 
          :key="index"
          @click="$router.push({ path: '/searchShow/' + item.id })"
        >
          <div class="list-title font-weight">
            <i class="iconfont icondengji"></i>{{item.basic_com_name}}
          </div>
          <div class="list-bref m-t-5">法人：{{item.basic_ceo_name}}</div>
          <div class="list-bref m-t-5">统一社会信用代码：{{item.basic_uscc_id_card}}</div>
        </div>
      </div>
      <el-empty v-if="dataList.length<=0" description="暂无数据"></el-empty>
      <pagination
        v-if="dataList.length>0"
        :total="total"
        :curPage="pageParams.page"
        :perPage="pageParams.size"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
export default {
  name: "Home",
  props: {},
  data() {
    return {
      pageLoading: false,
      pageParams: {
        page: 1,
        size: 10,
      },
      total: 0,
      dataList: [],
      search_key: this.$route.params.val+'',
      selectIdx: this.$route.params.idx+'',
    };
  },
  mounted() {
    if(this.$route.params.val && this.$route.params.val != '') l_loadData(this);
  },
  beforeRouteUpdate(to,from,next){
    this.search_key = to.params.val+''
    this.selectIdx = to.params.idx+''
    next()
    l_loadData(this)
  },
  methods: {
    // @@ 改变当前页事件
    currentChange(val) {
      this.pageParams.page = val;
      l_loadData(this);
    },
    // @@ 搜索
    doSearch(){
      if(!this.search_key) return this.$message.error('请输入搜索条件');
      l_loadData(this);
    }
  },
};

// 获取数据
function l_loadData(pg){
  pg.pageLoading = true
  var values = pg.pageParams
  if(pg.selectIdx ==  '1'){
    values.comCode = pg.search_key
    delete values.comName
    delete values.principal
  }
  if(pg.selectIdx ==  '2'){
    values.comName = pg.search_key
    delete values.comCode
    delete values.principal
  }
  if(pg.selectIdx ==  '3'){
    values.principal = pg.search_key
    delete values.comName
    delete values.comCode
  }
  requestGet("api/company/search", values)
    .then((res) => {
      pg.dataList = res.data.pageData || []
      pg.total = res.data.count
      pg.pageLoading = false
    })
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false
    });
}

</script>
<style lang="scss" scoped>
.news-list {
  width: 1200px;
  margin: 0 auto;
  .cont {
    background: #fff;
    padding: 20px;
    .search-box{
        border-bottom: 2px solid #f00;
        padding: 40px 240px;
        ::v-deep .el-input-group__prepend{
            width: 150px!important;
            background: #fff;
        }
        ::v-deep .el-button{
            background: #db3e3e !important;
            color: #fff;
            font-weight: bold;
            border-radius: 0;
            border: 1px solid #db3e3e !important;
            width: 100px;
        }
    }
    .cont-list {
      .list-cell {
        border-bottom: 1px solid #e8e8e8;
        .list-title {
          font-size: 16px;
          line-height: 22px;
          .iconfont {
            margin-right: 10px;
            color: #db3e3e;
            font-size: 20px;
            }
        }
        .list-bref {
          font-size: 14px;
          line-height: 22px;
        }
        
      }
      .list-cell:hover {
        background: #e6f7ff;
      }
    }
  }
}
</style>
